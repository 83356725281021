.box-left{
    float:left;
    text-align: justify;
    width:50%;
    margin:auto;
    padding: 30px;
}

.top-content{
    color: #000000;
    border-color: #000000;
    border-radius: 20px;
    font-size: 20px; 
    float: right; 
    padding: 8px; 
    /* width: 200px;  */
    display: flex; 
    flex-direction: row;
}

.top-content .icon-world{
    margin:10px;
}

.top-content .translate{
    width: 90px;
}

ol {
    font-size: 12px;
    font-weight: 300;
}

.column1{
    padding-left:0.2em;
    margin-left:10px;
}

.middle-content{
    margin-top: 100px;
}

.middle-content h1{
    font-size: 18px;
    font-weight: 500;
}

.middle-content h2{
    font-size: 12px;
    font-weight: 300;
}

.middle-content h3{
    font-size: 12px;
    font-weight: 300;
}

.middle-content-btn {
    float: right;
}

.middle-content-btn .btn-back{
    border-color: #FF6000;
    color: #FF6000;
    font-size: 16px;
    width: 100px;
    font-weight: bold;
    height: 50px;
    border-radius: 30px;
}

.middle-content-btn .btn-go{
    margin-left: 10px;
    background-color: #FF6000;
    color: white;
    font-weight: bold;
    width: 100px;
    height: 50px;
    font-size: 16px;
    border-radius: 30px;
}

.bottom-content{
    float: right;
    width: 50%;
    height: 100vh;
    /* background-image: url("/src/assets/image/backlnd1.png"); */
    /* background-size: cover; */
    background: no-repeat;
    position: fixed;
    right:0;
}

.bottom-content img{
    right: 0;
    bottom: 0; 
    position: fixed;
}

@media only screen and (max-width:990px){
    .box-left{
        float:left;
        width:100%;
    }

    .top-content{
        padding:auto;
    }
}