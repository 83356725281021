.btn-schedule-card{
    height: 30px;
    border-radius:22px;
    border:1px solid #FF6000;
    background-color:transparent;
    font-weight:600;
    font-size:16;
    color:#FF6000;
    margin-right:10px;
    padding-right:15px;
    padding-left:15px;
}

.btn-modal {
    height: 30px;
    border-radius:22px;
    border:1px solid #FF6000;
    background-color:transparent;
    font-weight:600;
    font-size:16;
    color:#FF6000;
    margin-right:10px;
    padding-right:15px;
    padding-left:15px;
}

.btn-modal:hover {
    background-color:#FF6000;
    color:white;
}

.btn-schedule-card:hover{
    background-color:#FF6000;
    color:white;
}

.translate {
    color: #000000;
    border-color: #000000;
    border-radius: 20;
    font-size: 20;
    float: right;
    padding: 8px;
    width: 200px;
    display: flex;
    flex-direction: row;
}

.translate-icon{
    margin: 10px;
}

.head-title-opening{
    margin-top: 100px;
}

.head-title-opening b{
    font-size: 18;
    font-weight: 600;
}

.body-opening{
    padding: 5px;
}

.body-opening hr{
    border: 2px solid #000000;
}

.btn-body-opening{
    width: 168px;
    height: 30px;
    border-radius: 22px;
    background-color:#FF6000;
    border: 1px #FF6000 solid;
    color: #FFFFFF;
    padding: 3px;            
}

.img-splash{
    right: 0;
    bottom: 0;
    position: fixed;         
}

.loading-react{
    display: flex;
    flex-direction:row;
}

.schedule-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}